<template>
  <SplitPage>
    <template #left-top>
      <BackToAuthorizationsIndex/>
    </template>

    <template #left-content>
      <FormCityAutocomplete v-model="cityStart"
                            id="startCity"
                            name="startCity"
                            type="text"
                            label="Ville de départ"
                            placeholder="Saisir et sélectionner une ville"/>
      <FormCityAutocomplete v-model="cityEnd"
                            id="endCity"
                            name="endCity"
                            type="text"
                            label="Ville d'arrivée"
                            placeholder="Saisir et sélectionner une ville"/>
      <button @click="callPathFromCities"
              class="btn btn-block btn-outline-secondary">
        Bouh
      </button>

      <FormInput v-model="latStart"
                name="startLat"
                id="startLat"
                type="number"
                label="Latitude de départ"/>
      <FormInput v-model="lonStart"
                name="startLon"
                id="startLon"
                type="number"
                label="Longitude de départ"/>
      <FormInput v-model="latEnd"
                name="endLat"
                id="endLat"
                type="number"
                label="Latitude d'arrivée"/>
      <FormInput v-model="lonEnd"
                name="endLon"
                id="endLon"
                type="number"
                label="Longitude d'arrivée"/>
      <button @click="callPathFromCoords"
              class="btn btn-block btn-outline-secondary">
        Bouh
      </button>
    </template>

    <!-- <template #right-content>
      <AuthorizationMap :authorization="authorization"
                        :steps="steps"
                        :center="center"/>
    </template> -->

    <template #right-content>
      <div class="authorization-map">
              <!-- @ready="onMapReady" -->
              <!-- @update:bounds="resize" -->
              <!-- @update:center="resize" -->
              <!-- @update:zoom="resize" -->
        <LMap ref="map"
              id="map"
              :options="map.options"
              :bounds="bounds">
          <!-- <LTileLayer :url="map.url"
                      attribution=""/> -->
          <!-- <LControlZoom position="topright"/> -->
          <LGeoJson v-for="(direction, index) in directions"
                    :key="index"
                    :geojson="direction"
                    :options-style="directionStyle(direction)"/>
        </LMap>
      </div>
    </template>
  </SplitPage>
</template>

<script>
import SplitPage from '@/components/SplitPage'
import BackToAuthorizationsIndex from '@/components/BackToAuthorizationsIndex'
import FormCityAutocomplete from '@/components/FormCityAutocomplete'
import FormInput from '@/components/FormInput'
// import AuthorizationMap from '@/components/AuthorizationMap'
import { LMap, LTileLayer, LControlZoom, LGeoJson } from 'vue2-leaflet'
import { bboxToBounds } from '@/utils'

import { findOneCity } from '@/api/premat/city'
import { pathFromCities, pathFromCoords } from '@/api/premat/path'

import { mapState, mapActions } from 'vuex'
import geojsonExtent from '@mapbox/geojson-extent'
import geojsonMerge from '@mapbox/geojson-merge'
import $ from 'jquery'

export default {
  name: 'AuthorizationsShowPage',
  components: {
    SplitPage,
    BackToAuthorizationsIndex,
    FormCityAutocomplete,
    FormInput,
    // AuthorizationMap,
    LMap,
    LTileLayer,
    LControlZoom,
    LGeoJson
  },
  data () {
    return {
      franceBounds: [0.702953, 45.487125, 4.839306, 48.004654],
      // cityStartVar: 'Le Plessis-Pâté, 91, France',
      // cityIdStartVar: 35759,
      // cityEndVar: 'Gif-sur-Yvette, 91, France',
      // cityIdEndVar: 35810,
      cityStartVar: 'Saclay, 91, France',
      cityIdStartVar: 35873,
      cityEndVar: 'Trouville, 76, France',
      cityIdEndVar: 31098,
      showRenewalModal: true,
      path: null,
      directions: [],
      map: {
        url: process.env.VUE_APP_TILE_SERVER_URL,
        options: {
          zoomSnap: 0.5,
          zoomControl: false,
          attributionControl: false
        }
      }
    }
  },
  computed: {
    bounds () {
      return bboxToBounds(this.franceBounds)
    },
    cityStart: {
      get () { return this.cityStartVar },
      set (value) {
        if (value == null) { return }
        const city = findOneCity(value)
        this.cityStartVar = value
        this.cityIdStartVar = city.id
      }
    },
    cityEnd: {
      get () { return this.cityEndVar },
      set (value) {
        if (value == null) { return }
        const city = findOneCity(value)
        this.cityEndVar = value
        this.cityIdEndVar = city.id
      }
    },
    latStart: {
      get () {
        return 48.617230
      },
      set (value) {
        console.log('BOUH - latStart - value =', value)
      }
    },
    lonStart: {
      get () {
        return 2.326847
      },
      set (value) {
        console.log('BOUH - lonStart - value =', value)
      }
    },
    latEnd: {
      get () {
        return 48.617230
      },
      set (value) {
        console.log('BOUH - latStart - value =', value)
      }
    },
    lonEnd: {
      get () {
        return 2.326847
      },
      set (value) {
        console.log('BOUH - lonStart - value =', value)
      }
    },
    ...mapState({
      user: state => state.user.user,
      authorization: state => state.authorization.authorization,
      steps: state => state.authorization.steps
    }),
    editLink () {
      return {
        name: 'authorizations.edit',
        params: {
          id: this.$route.params.id
        }
      }
    },
    showStepsButtonDisabled () {
      return this.steps === undefined || this.steps.length === 0
    },
    center () {
      if (this.steps.length) {
        return geojsonExtent.bboxify(geojsonMerge.merge(this.steps)).bbox
      } else {
        // const cityStart = [this.authorization.city_start.latitude, this.authorization.city_start.longitude]
        // const cityEnd = [this.authorization.city_end.latitude, this.authorization.city_end.longitude]
        // return [cityStart, cityEnd]
        // The authorization does not really have city_start and city_end now
        // Return GPS coordinates to see the France
        return [[51.115187, -5.344928], [40.957331, 9.975715]]
      }
    },
    showRenewalButton () {
      return ['not valid', 'validated', 'to be renewed'].includes(this.authorization.status)
    },
    authorizationIsWaitingForValidation () {
      return this.authorization.status === 'waiting for validation'
    },
    showSubmitForValidationButton () {
      return this.authorization.status === 'ongoing input' && this.steps.length >= 2
    },
    userCanValidateAuthorization () {
      return this.user && this.user.user && ['manager', 'admin'].includes(this.user.user.access)
    }
  },
  methods: {
    callPathFromCities () {
      pathFromCities(this.cityIdStartVar, this.cityIdEndVar, 177, 1).then(path => {
        this.path = path.data
      })
        .catch(error => alert('ERREUR : pathFromCities : ' + error))
    },
    callPathFromCoords () {
      pathFromCoords(0, 0, 0, 0, 177, 1).then(path => {
        this.path = path.data
      })
        .catch(error => alert('ERREUR : pathFromCities : ' + error))
    },
    ...mapActions({
      getAuthorization: 'authorization/getAuthorization',
      getSteps: 'authorization/getSteps',
      resetState: 'authorization/resetState'
    }),
    showSteps () {
      if (this.showStepsButtonDisabled) return
      this.$router.push({
        name: 'authorizations.steps.show',
        params: {
          id: this.authorization.id,
          num_step: this.steps[0].properties.num_step
        }
      })
    },
    editSteps () {
      this.$router.push({
        name: 'authorizations.steps.index',
        params: {
          id: this.authorization.id
        }
      })
    },
    submitForValidation () {
      this.goToValidation()
    },
    goToValidation () {
      this.$router.push({
        name: 'authorizations.validate',
        params: {
          id: this.$route.params.id
        }
      })
    },
    closeModal () {
      $('#renewal-modal').modal('hide')
    }
  },
  mounted () {
    // TODO
  },
  watch: {
    path () {
      console.log('BOUH path = ', this.path)
      const directions = []
      directions.push(geojsonMerge.merge(this.path.paths[0]))
      this.directions = directions
    }
  },
  beforeDestroy () {
    this.closeModal()
    $('#renewal-modal').modal('dispose')
    this.resetState()
  }
}
</script>

<style scoped lang="scss">
  .actions {
    margin-top: 80px;
  }
</style>
