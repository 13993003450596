import client from './client'

export function pathFromCities (startCityId, endCityId, tonnage, useNotValid) {
  return client.get('/path/?'
    + 'id_city_start=' + startCityId + '&'
    + 'id_city_end=' + endCityId + '&'
    + 'tonnage=' + tonnage + '&'
    + 'use_not_valid=' + useNotValid + '&'
  )
}

export function pathFromCoords (startLat, startLon, endLat, endLon, tonnage, useNotValid) {
  return client.get('/path/coords/'
    + 'start_latitude=' + startLat + '&'
    + 'start_longitude=' + startLon + '&'
    + 'end_latitude=' + endLat + '&'
    + 'end_longitude=' + endLon + '&'
    + 'tonnage=' + tonnage + '&'
    + 'use_not_valid=' + useNotValid + '&'
  )
}
